html {
  font-size: 14px;
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  //padding: 0 316px;
  position: relative;
  z-index: 1;
  //padding: 0 15px;
}

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

.w-33 {
  width: calc(100% / 3);
  padding: 15px;
}

.container {
  padding: 0 316px;
}

.extra-container {
  padding: 0 70px 0 0;
}

.item-view {
  width: calc(100% + 316px);
}


.contacts-wrap {
  /*386 = 70 + 316*/
  margin: 0 -386px 0 -77px;
}

.principles-bg,
.prices-bg {
  right: -386px;
}

.header-wrap {
  margin: 0 0 0 -316px;
}

.form-sended {
  margin: 0 0 0 -316px;
}

.production-info-wrap {
  margin: 0 -316px 0 0;
}

/*.materials-info {
  margin: 0 -316px 0 0;
}*/
.about-info-bg,
.materials-bg {
  span {
    right: -316px;
  }
}
.equipment-info {
  .bg-text {
    right: -316px;
  }
}

.main-logo {
  width: 316px;
  padding: 0 15px 0 48px;
}

.header-list {
  width: calc(100% - 316px);
}
